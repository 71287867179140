// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'http://5.196.23.101:1378/parse/classes/',
  searchUrl: 'http://5.196.23.101:1378/parse/classes/',
  parseId: 'FootyBox865565GHJGJKGJGYUTFRJOIHI',
  firebaseConfig: {
    apiKey: "AIzaSyDTOX1bKwmn5-UUyMSHBdsQyWfxYliB1yk",
    authDomain: "foot-box-57aa2.firebaseapp.com",
    projectId: "foot-box-57aa2",
    storageBucket: "foot-box-57aa2.appspot.com",
    messagingSenderId: "681308732037",
    appId: "1:681308732037:web:e24d0c802e62b8bf1047ec",
    measurementId: "G-EC7JCHF2CV"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
