import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocalStoreService } from './local-store.service';

export interface IMenuItem {
    id?: string;
    title?: string;
    description?: string;
    type: string;       // Possible values: link/dropDown/extLink
    name?: string;      // Used as display text for item and title for separator type
    state?: string;     // Router state
    icon?: string;      // Material icon name
    tooltip?: string;   // Tooltip text
    disabled?: boolean; // If true, item will not be appeared in sidenav.
    sub?: IChildItem[]; // Dropdown items
    badges?: IBadge[];
    active?: boolean;
}
export interface IChildItem {
    id?: string;
    parentId?: string;
    type?: string;
    name: string;       // Display text
    state?: string;     // Router state
    icon?: string;
    sub?: IChildItem[];
    active?: boolean;
}

interface IBadge {
    color: string;      // primary/accent/warn/hex color codes(#fff000)
    value: string;      // Display text
}

interface ISidebarState {
    sidenavOpen?: boolean;
    childnavOpen?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    public sidebarState: ISidebarState = {
        sidenavOpen: true,
        childnavOpen: false
    };
    constructor(
        private store: LocalStoreService
    ) {
        
    }

    defaultMenu: IMenuItem[] = [
        {
            name: 'Utilisateurs',
            description: '',
            type: 'link',
            icon: 'i-MaleFemale',
            state: '/users'
        },
        {
            name: 'Matchs',
            description: '',
            type: 'link',
            icon: 'i-Business-Mens',
            state: '/players',
        },
        {
            name: 'Terrains',
            description: '',
            type: 'link',
            icon: 'i-Business-Mens',
            state: '/terrains',
        },
        {
            name: 'Paiements',
            description: '',
            type: 'link',
            icon: 'i-Ticket',
            state: '/payments'
        },
        {
            name: 'Galerie photos',
            description: '',
            type: 'link',
            icon: 'i-Camera',
            state: '/gallery'
        },
        {
            name: 'Vidéothèque',
            description: '',
            type: 'link',
            icon: 'i-Camera',
            state: '/videotheque',
        },
        // {
        //     name: 'Statistiques',
        //     description: 'Statistiques des publications',
        //     type: 'link',
        //     icon: 'i-Statistic',
        //     state: '/statistiques'
        // },
        //
        //  {
        //     name: 'Partenaires',
        //     description: '',
        //     type: 'link',
        //     icon: 'i-Shop',
        //     state: '/partners'
        // },
        // {
        //     name: 'Groupes de discussion',
        //     description: '',
        //     type: 'link',
        //     icon: 'i-Speach-Bubbles',
        //     state: '/chat'
        // },{
        //     name: 'Publications',
        //     description: '',
        //     type: 'dropDown',
        //     icon: 'i-File-Clipboard-Text--Image',
        //     state: '/publications',
        //     sub: [
        //         { icon: 'i-Folders', name: 'Mes publications', state: '/publications', type: 'link' },
        //         { icon: 'i-Calendar', name: 'Évènements', state: '/events', type: 'link' },
        //         { icon: 'i-Newspaper', name: 'Actualités', state: '/news', type: 'link' },
        //         { icon: 'i-Shopping-Bag', name: 'Boutique', state: '/boutique', type: 'link' },
        //         { icon: 'i-Money-Bag', name: 'Convention', state: '/conventions', type: 'link' }
        //     ]
        // },
        // {
        //     name: 'Annonces',
        //     description: '',
        //     type: 'link',
        //     icon: 'i-File-Horizontal-Text',
        //     state: '/annonces'
        // },
        // {
        //     name: 'Présentation du club',
        //     description: '',
        //     type: 'dropDown',
        //     icon: 'i-Information',
        //     state: '/presentation',
        //     sub: [
        //         { icon: '', name: 'Histoire', state: '/history', type: 'link' },
        //         { icon: '', name: 'Les présidents', state: '/presidents', type: 'link' },
        //         { icon: '', name: 'Palmarès', state: '/titles', type: 'link' },
        //         { icon: '', name: 'Comité', state: '/comite', type: 'link' },
        //         { icon: '', name: 'Staff technique / médical', state: '/staff', type: 'link' },
        //         { icon: '', name: 'Joueurs', state: '/players', type: 'link' }
        //     ]
        // },
        // {
        //     name: 'Listes des membres',
        //     description: '',
        //     type: 'link',
        //     icon: 'i-Business-Mens',
        //     state: '/membres'
        // },
        {   
            name: 'Paramètres',
            description: '',
            type: 'dropDown',
            icon: 'i-Gear',
            sub: [
                //{ icon: 'i-Library', name: 'Compétitions', state: '/settings/competition', type: 'link' },
                //{ icon: 'i-Library', name: 'Catégories d\'équipe', state: '/settings/teams', type: 'link' },
                //{ icon: 'i-Management', name: 'Objectifs', state: '/settings/objects', type: 'link' },
                //{ icon: 'i-File-Clipboard-File--Text', name: 'Secteurs', state: '/settings/sectors', type: 'link' },
                //{ icon: 'i-Building', name: 'Types de profil', state: '/settings/types', type: 'link' },
                { icon: 'i-Copyright', name: 'A propos de nous', state: '/a-propos-de-nous', type: 'link' },
                { icon: 'i-Copyright', name: 'CGU', state: '/cgu', type: 'link' },
                { icon: 'i-Copyright', name: 'la politique de confidentialité', state: '/politique', type: 'link' },
              //  { icon: 'i-Shopping-Bag', name: 'Catégories-boutique', state: '/settings/bot-cat', type: 'link' }
            ]
        }
    ];

   
   


    // sets iconMenu as default;
    menuItems = new BehaviorSubject<IMenuItem[]>(this.defaultMenu);
    // navigation component has subscribed to this Observable
    menuItems$ = this.menuItems.asObservable();

    // You can customize this method to supply different menu for
    // different user type.
}
