import { Injectable } from "@angular/core";
import { LocalStoreService } from "./local-store.service";
import { Router } from "@angular/router";
import { of, Observable } from "rxjs";
import { delay } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import 'rxjs/add/operator/catch';
import 'rxjs/add/Observable/throw';
import { AngularFireAuth } from "@angular/fire/auth";

@Injectable({
  providedIn: "root"
})
export class AuthService {


  constructor(private store: LocalStoreService, private http: HttpClient, private auth: AngularFireAuth) {
    this.checkAuth();
  }

  checkAuth() {
    // this.authenticated = this.store.getItem("demo_login_status");
  }

  getuser() {
    return of({});
  }

  login(login, password) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('X-Parse-Application-Id', environment.parseId)
      .set('X-Parse-Revocable-Session', '1');

      const loginPassword = Object.assign({});

      loginPassword.username = login;
      loginPassword.password = password;

    return this.http.post<any[]>('http://5.196.23.101:1378/parse/login', loginPassword, { headers }).catch(this.errorHandler);
  
  }

  signin(uid) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('X-Parse-Application-Id', environment.parseId)
      .set('X-Parse-Revocable-Session', '1');

    console.log(uid);

    return this.http.get<any[]>(environment.baseUrl + '_User?where={"uid":"'+uid+'"}', { headers }).catch(this.errorHandler);
  }
  
  errorHandler(error: HttpErrorResponse) {
    return Observable.throw(error.message || "server error")
  }
  signout() {
    this.store.setItem('admin_login_status', false);
    return this.auth.auth.signOut();
  }
}
