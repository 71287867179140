import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGaurd } from './shared/services/auth.gaurd';
import { BlankLayoutComponent } from './shared/components/layouts/blank-layout/blank-layout.component';
import { AdminLayoutSidebarCompactComponent } from './shared/components/layouts/admin-layout-sidebar-compact/admin-layout-sidebar-compact.component';
import { AdminLayoutSidebarLargeComponent } from './shared/components/layouts/admin-layout-sidebar-large/admin-layout-sidebar-large.component';

const adminRoutes: Routes = [
  {
    path: 'settings',
    loadChildren: () => import('./views/settings/settings.module').then(m => m.SettingsModule)
  },
  {
    path: 'statistiques',
    loadChildren: () => import('./views/statistiques/statistiques.module').then(m => m.StatistiquesModule)
  },
  {
    path: 'news',
    loadChildren: () => import('./views/news/news.module').then(m => m.NewsModule)
  },
  {
    path: 'events',
    loadChildren: () => import('./views/events/events.module').then(m => m.EventsModule)
  },
  {
    path: 'conventions',
    loadChildren: () => import('./views/conventions/conventions.module').then(m => m.ConventionsModule)
  },
  {
    path: 'boutique',
    loadChildren: () => import('./views/boutique/boutique.module').then(m => m.BoutiqueModule)
  },
  {
    path: 'users',
    loadChildren: () => import('./views/users/users.module').then(m => m.UsersModule)
  },
  {
    path: 'partners',
    loadChildren: () => import('./views/partners/partners.module').then(m => m.PartnersModule)
  },
  {
    path: 'chat',
    loadChildren: () => import('./views/groupes/groupes.module').then(m => m.GroupesModule)
  },
  {
    path: 'a-propos-de-nous',
    loadChildren: () => import('./views/history/history.module').then(m => m.HistoryModule)
  },
  {
    path: 'presidents',
    loadChildren: () => import('./views/president/president.module').then(m => m.PresidentModule)
  },
  {
    path: 'annonces',
    loadChildren: () => import('./views/annonces/annonces.module').then(m => m.AnnoncesModule)
  },
  {
    path: 'gallery',
    loadChildren: () => import('./views/gallery/gallery.module').then(m => m.GalleryModule)
  },
  {
    path: 'videotheque',
    loadChildren: () => import('./views/staff/staff.module').then(m => m.StaffModule)
  },
  {
    path: 'payments',
    loadChildren: () => import('./views/ticket/ticket.module').then(m => m.TicketModule)
  },
  {
    path: 'titles',
    loadChildren: () => import('./views/title/title.module').then(m => m.TitleModule)
  },
  {
    path: 'comite',
    loadChildren: () => import('./views/comite/comite.module').then(m => m.ComiteModule)
  },
  {
    path: 'terrains',
    loadChildren: () => import('./views/membre/membre.module').then(m => m.MembreModule)
  },
  {
    path: 'players',
    loadChildren: () => import('./views/players/players.module').then(m => m.PlayersModule)
  },
  {
    path: 'publications',
    loadChildren: () => import('./views/publication/publication.module').then(m => m.PublicationModule)
  },
  {
    path: 'cgu',
    loadChildren: () => import('./views/cgu/cgu.module').then(m => m.CguModule)
  },
  {
    path: 'politique',
    loadChildren: () => import('./views/politique/politique.module').then(m => m.PolitiqueModule)
  }
];

const routes: Routes = [
  {
    path: '',
    redirectTo: 'statistiques',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessions',
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule)
      }
    ]
  },
  {
    path: '',
    component: BlankLayoutComponent,
    children: [
      {
        path: 'error',
        loadChildren: () => import('./views/others/others.module').then(m => m.OthersModule)
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutSidebarLargeComponent,
    //canActivate: [AuthGaurd],
    children: adminRoutes
  },
  {
    path: '**',
    redirectTo: 'error'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
