import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {DateUtility} from "../DateUtility";

@Injectable({
    providedIn: 'root'
})
export class SharedService {

    constructor(
        private http: HttpClient
    ) { }

    findAllMetier() {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);
        return this.http.get<any[]>(
            environment.baseUrl + 'type_profil?include=subSpecialites&include=subSpecialites.subSubSpecialites',
            { headers })
    }
    findAllCities() {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);
        return this.http.get<any[]>(environment.baseUrl + 'villes?limit=900', { headers })
    }

    findAllStructure() {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);
        return this.http.get<any[]>(environment.baseUrl + 'structures?order=-createdAt', { headers })
    }
    getLikes() {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        return this.http.get<any[]>(environment.baseUrl + 'likes?order=-createdAt&count=1', { headers });
    }
    getComments() {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        return this.http.get<any[]>(environment.baseUrl + 'comments1?order=-createdAt&count=1', { headers });
    }

    findAllSectors() {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        return this.http.get<any[]>(environment.baseUrl + 'sectors?order=-createdAt', { headers });
    }
    saveSector(sector) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        const sectorToSave = Object.assign({});
        sectorToSave.name = sector.nom;
        sectorToSave.name_ar = sector.nomAr;

        if (sector.id) {
            return this.http.put<any[]>(environment.baseUrl + 'sectors/' + sector.id, sectorToSave, { headers });
        } else {
            return this.http.post<any[]>(environment.baseUrl + 'sectors', sectorToSave, { headers });
        }
    }

    findAllStadium() {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        return this.http.get<any[]>(environment.baseUrl + 'stadium?order=order', { headers });
    }

    findAllCategoriesEquipe() {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        return this.http.get<any[]>(environment.baseUrl + 'equipeCat?order=order', { headers });
    }

    saveCategoryEquipe(cat) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        const catToSave = Object.assign({});
        catToSave.name = cat.nom;
        catToSave.name_ar = cat.nomAr;
        catToSave.image = cat.image;

        if (cat.id) {
            return this.http.put<any[]>(environment.baseUrl + 'equipeCat/' + cat.id, catToSave, { headers });
        } else {
            return this.http.post<any[]>(environment.baseUrl + 'equipeCat', catToSave, { headers });
        }
    }

    saveTeamPost(name, order, id) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        const postToSave = Object.assign({});
        postToSave.name = name;
        postToSave.order = order.toString();
        postToSave.players_ids = [];
        postToSave.ide = id;

            return this.http.post<any[]>(environment.baseUrl + 'postes', postToSave, { headers });
        
    }

    findAllObjects() {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        return this.http.get<any[]>(environment.baseUrl + 'objectifs1?order=-createdAt', { headers });
    }
    saveObject(object) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        const objectToSave = Object.assign({});
        objectToSave.name = object.nom;
        objectToSave.name_ar = object.nomAr;
        objectToSave.picture = object.image;

        if (object.id) {
            return this.http.put<any[]>(environment.baseUrl + 'objectifs1/' + object.id, objectToSave, { headers });
        } else {
            return this.http.post<any[]>(environment.baseUrl + 'objectifs1', objectToSave, { headers });
        }
    }
    findAllCompetitions() {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        return this.http.get<any[]>(environment.baseUrl + '_User?order=familyname', { headers });
    }
    saveCompetition(comp) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        const compToSave = Object.assign({});
        compToSave.name = comp.nom;
        compToSave.picture = comp.image;

        if (comp.id) {
            return this.http.put<any[]>(environment.baseUrl + 'competitions/' + comp.id, compToSave, { headers });
        } else {
            return this.http.post<any[]>(environment.baseUrl + 'competitions', compToSave, { headers });
        }
    }
    saveStructure(str) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        const strToSave = Object.assign({});
        strToSave.name = str.nom;
        strToSave.type = str.type;

        if (str.id) {
            return this.http.put<any[]>(environment.baseUrl + 'structures/' + str.id, strToSave, { headers });
        } else {
            return this.http.post<any[]>(environment.baseUrl + 'structures', strToSave, { headers });
        }
    }
    getAnnonceById(id) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);
        return this.http.get<any[]>(environment.baseUrl + 'offers/' + id, { headers })
    }

    findAllTypes() {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        return this.http.get<any[]>(environment.baseUrl + 'type_pro1?order=-createdAt', { headers });
    }
    saveType(type) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        const typeToSave = Object.assign({});
        typeToSave.name = type.name;

        if (type.id) {
            return this.http.put<any[]>(environment.baseUrl + 'type_pro1/' + type.id, typeToSave, { headers });
        } else {
            return this.http.post<any[]>(environment.baseUrl + 'type_pro1', typeToSave, { headers });
        }
    }
    addPostsToSearch(p1, p2, p3, p4) {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post<any[]>(
            environment.searchUrl + "/insert?p1=" + p1 + "&p2=" + p2 + "/" + p4 + "&p3=" + p3,
            { headers })
    }

    deletePostsFromSearch(p1, p2, p3) {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.post<any[]>(
            environment.searchUrl + "/delete?p1=" + p1 + "&p2=" + p2 + "/" + p3,
            { headers })
    }
    getAllPartners() {
        const headers = new HttpHeaders().set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        return this.http.get<any[]>(environment.baseUrl + 'partners', { headers });
    }

    getHistory() {
        const headers = new HttpHeaders().set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        return this.http.get<any[]>(environment.baseUrl + 'history', { headers });

    }
    getCgu() {
        const headers = new HttpHeaders().set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        return this.http.get<any[]>(environment.baseUrl + 'condition', { headers });

    }
    getPolitique() {
        const headers = new HttpHeaders().set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        return this.http.get<any[]>(environment.baseUrl + 'politique', { headers });

    }

    saveHistory(history) {
        const headers = new HttpHeaders().set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        const historyToSave = Object.assign({});


        historyToSave.text = history.text;
        historyToSave.pictures = history.pictures;

        if (history.id) {
            return this.http.put<any[]>(environment.baseUrl + 'history/' + history.id, historyToSave, { headers });
        }
        else {
            return this.http.post<any[]>(environment.baseUrl + 'history', historyToSave, { headers });
        }
    }
    saveCgu(cgu) {
        const headers = new HttpHeaders().set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        const cguToSave = Object.assign({});


        cguToSave.text = cgu.text;

        if (cgu.id) {
            return this.http.put<any[]>(environment.baseUrl + 'condition/' + cgu.id, cguToSave, { headers });
        }
        else {
            return this.http.post<any[]>(environment.baseUrl + 'condition', cguToSave, { headers });
        }
    }
    savePolitique(cgu) {
        const headers = new HttpHeaders().set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        const cguToSave = Object.assign({});


        cguToSave.text = cgu.text;

        if (cgu.id) {
            return this.http.put<any[]>(environment.baseUrl + 'politique/' + cgu.id, cguToSave, { headers });
        }
        else {
            return this.http.post<any[]>(environment.baseUrl + 'politique', cguToSave, { headers });
        }
    }

    getMatchList(competition){
        const headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this.http.get<any[]>("https://apifoot.santelive.tk/matches/1/"+ competition+"/home", {headers});

    }

    getAllMatches() {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        return this.http.get<any[]>(environment.baseUrl + 'match?include=stadium&include=organizer&order=titre', { headers });
    }

    getMatchListNotYet(competition){
        const headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this.http.get<any[]>("https://apifoot.santelive.tk/matches/0/"+ competition+"/all", {headers});

    }

    saveTicket(ticket) {
        const headers = new HttpHeaders().set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        const ticketToSave = Object.assign({});

        ticketToSave.type = ticket.type;
        ticketToSave.amount = ticket.amount;
        ticketToSave.date = DateUtility.getTimeFromDate(ticket.date.day + '/' + ticket.date.month + '/' + ticket.date.year);

        var user = new Map();
        user["__type"] = "Pointer";
        user["className"] = "_User";
        user["objectId"] = ticket.user;
        ticketToSave.user = user;

        if (ticket.match != "") {
            var match = new Map();
            match["__type"] = "Pointer";
            match["className"] = "match";
            match["objectId"] = ticket.match;
            ticketToSave.match = match;
        } else {
            ticketToSave.match = null;
        }

        if (ticket.id) {
            return this.http.put<any[]>(environment.baseUrl + 'payments/' + ticket.id, ticketToSave, { headers });
        }
        else {
            return this.http.post<any[]>(environment.baseUrl + 'payments', ticketToSave, { headers });
        }
    }

    savePresident(president) {
        const headers = new HttpHeaders().set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        const presidentToSave = Object.assign({});


        presidentToSave.name = president.name;
        presidentToSave.ordre = president.ordre;
        /* presidentToSave.period = president.period;
        presidentToSave.description = president.description; */
        presidentToSave.image = president.image == '' ? "http://5.196.23.101:1378/parse/files/"+environment.parseId+"/c0fc54f8af07ec91f222016551269b5f_image.jpg": president.image;

        if (president.id) {
            return this.http.put<any[]>(environment.baseUrl + 'presidents/' + president.id, presidentToSave, { headers });
        }
        else {
            return this.http.post<any[]>(environment.baseUrl + 'presidents', presidentToSave, { headers });
        }
    }

    getTickets() {
        const headers = new HttpHeaders().set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        return this.http.get<any[]>(environment.baseUrl + 'payments?include=user&include=match', { headers });

    }

    getPresidents() {
        const headers = new HttpHeaders().set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        return this.http.get<any[]>(environment.baseUrl + 'presidents?order=-ordre', { headers });

    }
    deletePresident(id) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        return this.http.delete<any[]>(environment.baseUrl + 'presidents/' + id, { headers });
    }

    deleteTicket(id) {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        return this.http.delete<any[]>(environment.baseUrl + 'payments/' + id, { headers });
    }

    saveNotification(notification) {
        const headers = new HttpHeaders().set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        const notificationToSave = Object.assign({});

        notificationToSave.message = notification.message;
        notificationToSave.users = notification.users;

        return this.http.post<any[]>(environment.baseUrl + 'notifications', notificationToSave, { headers });
    }

    getOrganizers() {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json').set('X-Parse-Application-Id', environment.parseId);

        return this.http.get<any[]>(environment.baseUrl + '_User?where={"organizer":true}&order=familyname', { headers });
    }


}
