import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../../../services/navigation.service';
import { SearchService } from '../../../../services/search.service';
import { AuthService } from '../../../../services/auth.service';
import { LocalStoreService } from 'src/app/shared/services/local-store.service';
import { Router } from '@angular/router';

import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { map } from 'rxjs/operators';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-header-sidebar-large',
  templateUrl: './header-sidebar-large.component.html',
  styleUrls: ['./header-sidebar-large.component.scss']
})
export class HeaderSidebarLargeComponent implements OnInit {

  admin: any;
  data: any;
  notifications = [];
  notification = {
    postId: "",
    postName: "",
    userId: "",
    userName: "",
    userImage: "",
    time: "",
    seen: false,
    type: "",
    message: ""
  }
  private dbPath = '/admin_notifications/';
  notificationsRef: AngularFireList<any> = null;
  connRef: AngularFireList<any> = null;

  constructor(
    private navService: NavigationService,
    public searchService: SearchService,
    private auth: AuthService,
    private store: LocalStoreService,
    private router: Router,
    private sharedService: SharedService,
    private db: AngularFireDatabase

  ) {
    this.notifications = [

    ];
  }

  ngOnInit() {
    this.admin = this.store.getItem("adminRaja");
    this.notificationsRef = this.db.list(this.dbPath);
    //this.connRef = this.db.list("/connexion");
    this.getNotsList();
  }

   getNotificationsList(): AngularFireList<any> {
    return this.notificationsRef;
  } 


   getNotsList() {
    this.getNotificationsList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ key: c.payload.key, ...c.payload.val() })
        )
      )
    ).subscribe(notifications => {
      this.data = notifications;
      this.data.sort(function (a, b) {
        var time1 = new Date(a.time).getTime()
        var time2 = new Date(b.time).getTime()
        if (time1 < time2)
          return -1;
        if (time1 > time2)
          return 1;
        return 0;
      });
      this.notifications = [];
      this.notificationsList();
    });
  }

  updateNotification(key: string, value: any): Promise<void> {
    return this.notificationsRef.update(key, value);
  }
   

  toggelSidebar() {
    const state = this.navService.sidebarState;
    if (state.childnavOpen && state.sidenavOpen) {
      return state.childnavOpen = false;
    }
    if (!state.childnavOpen && state.sidenavOpen) {
      return state.sidenavOpen = false;
    }
    if (!state.sidenavOpen && !state.childnavOpen) {
      state.sidenavOpen = true;
      setTimeout(() => {
        state.childnavOpen = true;
      }, 50);
    }
  }

  signout() {
    this.auth.signout().then(resp => {
      this.store.setItem("admin_login_status", false);
      this.store.removeItem('adminRaja');
      this.router.navigateByUrl("/sessions/login");
    });
  }
  notificationsLength = 0;

  notificationsList() {
    for (let item of this.data) {
      if (item.type == 'post') {
        this.sharedService.getAnnonceById(item.key).subscribe(data => {
          this.notification = {
            postId: item.key,
            postName: data["title"],
            userId: item.id,
            userImage: item.image,
            userName: item.name,
            time: item.time,
            seen: item.seen,
            type: item.type,
            message: "a ajouté une nouvelle publication"
          }
          if (this.notification.seen == false) {
            this.notificationsLength++;
          }
          this.notifications.push(this.notification);
        })
      }
      else {
        this.notification = {
          postId: item.key,
          postName: "",
          userId: item.id,
          userImage: item.image,
          userName: item.name,
          time: item.time,
          seen: item.seen,
          type: item.type,
          message: "s'est inscrit à Foot Box"
        }
        if (this.notification.seen == false) {
          this.notificationsLength++;
        }
        this.notifications.push(this.notification);
      }
    }
    this.notifications.reverse();
  }

  onOpenNotification(item) {
    if (item.seen == false) {
      item.seen = true;
      this.updateNotification(item.postId, { seen: true })
        .catch(err => console.log(err));
      this.notificationsLength--;
    }
  }

}
