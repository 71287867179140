
export class DateUtility {

    static convertDateToString(dateTime : any): string {
        let date = new Date(dateTime);
        let d =date.getDate();
        let m =date.getMonth()+1;
        let y =date.getFullYear();
        return y+"-"+ ("00" + (m)).substr(-2) +"-"+("00" + d).substr(-2) ;
    }

    static generateStringValue(length : any) : string {
        var chars = "0123456789";
        var string_length = length;
        var randomstring = '';
        for (var i=0; i<string_length; i++) {
            var rnum = Math.floor(Math.random() * chars.length);
            randomstring += chars.substring(rnum,rnum+1);
        }
        return randomstring
    }

    static getWeekNumber(d){
        // Copy date so don't modify original
        d = new Date(+d);
        d.setHours(0,0,0,0);
        // Set to nearest Thursday: current date + 4 - current day number
        // Make Sunday's day number 7
        d.setDate(d.getDate() + 4 - (d.getDay()||7));
        // Get first day of year
        var yearStart = new Date(d.getFullYear(),0,1);
        // Calculate full weeks to nearest Thursday
        var weekNo = Math.ceil(( ( (d - yearStart.getTime()) / 86400000) + 1)/7);
        // Return array of year and week number
        return [d.getFullYear(), weekNo];
    }

    static getTimeFromDate(date){
        let time = new Date().setUTCFullYear(date.split("/",3)[2],date.split("/",3)[1]-1,date.split("/",3)[0]);

        return time;
    }

    static getTimeFromDateTime(date, time) {
        var datetime = new Date(date.year+'/'+date.month+'/'+date.day +' '+time);
        return datetime.getTime();
    }

    static convertHmsToSecondsOnly(str) {
        var p = str.split(':');
        var d = Number(str.split(':')[0])*60+Number(str.split(':')[1])*1000;
        console.log(d);

        return p[0]*3600+p[1]*60;
    }

    static convertSecondstotime(secs)
    {
        var t: any = new Date(1970,0,1);
        t.setSeconds(secs);
        var s = t.toTimeString().substr(0,5);
        if(secs > 86399) {
            return Math.floor((t - Date.parse("1/1/70")) / 3600000) + s.substr(2);
        }

        return s;
    }

    static days(current) {
    var week = [];
    // Starting Monday not Sunday
    var first = current.getDate() - current.getDay() + 1;
    current.setDate(first);
    for (var i = 0; i < 7; i++) {
        week.push(new Date(+current).getDate());
        current.setDate(current.getDate()+1);
    }
    return week;
}

}












